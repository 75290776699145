import React, { useEffect } from "react";
import img1 from "../images/main1.jpg";
import img2 from "../images/main2.jpg";

const Main = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <div className="w-full bg-[#001f54] px-8 md:px-24 lg:px-48 py-16 md:py-32 lg:py-48 xl:px-[400px]">
        <div className="flex flex-col gap-8">
          <h1
            id="head"
            className="font-['League_Gothic'] text-4xl md:text-5xl lg:text-6xl font-light text-white"
          >
            Why Choose Us?
          </h1>
          <div className="font-['Work_Sans'] text-white text-lg md:text-xl">
            At American High-Tech Transcription & Reporting, Inc., <br />
            transcription is more than just converting speech to text; <br />
            it’s about accuracy, clarity, and capturing every detail. <br />
            Our experts are adept at transcribing content in English and <br />
            other languages such as Spanish, Creole, Russian, and more. <br />
            We take pride in delivering precise transcriptions tailored <br />
            to the specific needs of government and government-related <br />
            organizations.
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-8 text-white mt-8 text-left">
          <div className="flex flex-col items-start">
            <h2 className="text-2xl md:text-3xl font-['League_Gothic']">30</h2>
            <p className="font-['Work_Sans'] tracking-tight leading-snug">
              Languages Supported
            </p>
          </div>

          <div className="flex flex-col items-start">
            <h2 className="text-2xl md:text-3xl font-['League_Gothic']">
              99.98
            </h2>
            <p className="font-['Work_Sans'] tracking-tight leading-snug">
              Accuracy Rate
            </p>
          </div>

          <div className="flex flex-col items-start">
            <h2 className="text-2xl md:text-3xl font-['League_Gothic']">24h</h2>
            <p className="font-['Work_Sans'] tracking-tight leading-snug">
              Fast Response & Delivery
            </p>
          </div>
        </div>

        <div className="relative flex flex-col lg:flex-row items-center mt-20 lg:mt-40 gap-8 lg:gap-40">
          <img
            src={img1}
            alt=""
            className="w-full lg:w-96 h-96 rounded-3xl grayscale mix-blend-screen bg-blue-700"
          />
          <div className="w-[400px]">
            <h1 className="font-['League_Gothic'] text-2xl md:text-4xl font-light text-white">
              Transcription Solutions Tailored to Your Needs
            </h1>
            <div className="font-['Work_Sans'] text-white text-lg md:text-xl mt-4">
              From audio and video to document and online <br />
              transcription, we’ve got you covered. High <br />
              accuracy and quick returns are our promise.
            </div>
          </div>
        </div>

        <div className="relative flex flex-col lg:flex-row items-center mt-20 lg:mt-40 gap-8 lg:gap-20">
          <div className="w-[400px]">
            <h1 className="font-['League_Gothic'] text-2xl md:text-4xl font-light text-white">
              Superior Quality Services
            </h1>
            <div className="font-['Work_Sans'] text-white text-lg md:text-xl mt-4">
              We specialize in law enforcement, <br />
              industrial security, oral histories and more. Focus on your <br />
              core tasks while we handle the transcriptions <br />
              and the translations.
            </div>
          </div>
          <img
            src={img2}
            alt=""
            className="w-full lg:w-96 h-96 rounded-full grayscale mix-blend-hard-light bg-blue-700"
          />
        </div>

        <div className="flex flex-col items-center text-center my-20 lg:my-40">
          <h1 className="font-['League_Gothic'] lg:text-2xl md:text-4xl  text-4xl font-light text-white">
            Join Us
          </h1>
          <div className="font-['Work_Sans'] text-white md:text-md text-lg my-4">
            We specialize in law enforcement, <br />
            industrial security, oral histories and more. Focus on your <br />
            core tasks while we handle the transcriptions <br />
            and the translations.
          </div>

          <a name="Button" href="/apply#head">
            <p className="bg-white w-28 rounded-full p-2 text-sm text-[#001f54]">
              Apply Now
            </p>
          </a>
        </div>

        <div id="about" className="flex flex-col gap-8 my-20 lg:my-40">
          <div className="font-['Work_Sans'] text-white text-md">About Us</div>
          <h1 className="font-['League_Gothic'] text-4xl md:text-5xl lg:text-6xl font-light text-white">
            We’re a Florida-based transcription and <br /> translation agency,
            providing fast, accurate, <br /> and worldwide services since 1994.
          </h1>
        </div>

        <div className="mt-20 lg:mt-40">
          <h1 className="font-['League_Gothic'] text-4xl md:text-5xl lg:text-6xl font-light text-white">
            Get To Know Us
          </h1>
          <div className="mt-20 flex flex-col lg:flex-row gap-10 lg:gap-20">
            <div className="flex flex-col gap-4">
              <h3 className="font-['Work_Sans'] text-white text-lg md:text-xl font-bold">
                Why should I outsource transcription work?
              </h3>
              <h5 className="font-['Work_Sans'] text-white text-md">
                Outsourcing saves cost and can protect you from departmental
                leaks. <br /> Also, you’ll leave the task to the experts!
              </h5>
            </div>
            <div className="flex flex-col gap-4">
              <h3 className="font-['Work_Sans'] text-white text-lg md:text-xl font-bold">
                What makes your team members skilled?
              </h3>
              <h5 className="font-['Work_Sans'] text-white text-md">
                Our personnel include experienced former court reporters,
                paralegals, medical transcriptionists, and secretaries. <br />
                They’ve all undergone thorough background checks and deliver
                top-notch work.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
