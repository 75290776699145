import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/footer";
import Router from "./router/router";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <Router />
      </ChakraProvider>
    </div>
  );
}

export default App;
