import React from "react";
import img1 from "../images/translation_1.jpg";
import img2 from "../images/translation_2.jpg";
import img3 from "../images/translation_3.jpg";
import img4 from "../images/translation_4.jpg";
const Translation = () => {
  return (
    <>
      <div className="font-['Work_Sans'] text-[#220135] pb-16">
        <div
          id="head"
          className="flex flex-row justify-between items-center max-w-screen-lg mx-auto px-4"
        >
          <div>Services</div>
          <div className="w-32 transform -rotate-12">
            <svg viewBox="0 0 200 200">
              <path
                d="M 74.29 88.023 C 73.17 87.882 73.726 78.735 73.831 77.25 C 74.032 74.409 76.317 71.065 78.91 69.904 C 80.949 68.992 82.523 68.925 84.72 68.925 C 86.715 68.925 87.433 70.093 88.61 71.618 C 90.054 73.487 89.804 76.424 90.447 78.61 C 90.565 79.007 90.821 80.831 91.312 80.569 C 93.043 79.646 94.221 76.939 95.419 75.454 C 98.663 71.434 111.77 57.097 115.035 68.055 C 116.346 72.453 116.116 77.313 116.116 81.874 L 116.116 86.717 C 116.116 86.787 116.539 86.212 116.818 86.064 C 118.154 85.354 119.615 84.722 121.006 84.132 C 126.835 81.661 133.936 82.636 140.136 82.636 C 143.397 82.636 143.838 84.87 143.838 87.778 C 143.838 93.187 139.218 99.04 135.57 102.714 C 134.632 103.657 133.415 104.176 132.544 105.053 C 131.397 106.207 135.786 105.161 137.407 105.161 C 140.914 105.161 146.442 104.002 148.809 107.338 C 151.235 110.757 149.465 116.572 147.836 119.852 C 145.987 123.576 143.582 125.924 139.217 126.191 C 137.591 126.29 135.511 126.487 133.894 126.191 C 132.791 125.989 132.651 125.533 132.651 126.98 C 132.651 129.968 130.032 132.416 127.301 133.291 C 122.05 134.974 115.511 132.997 111.28 129.864 C 107.75 127.25 105.755 123.691 103.849 119.852 C 103.325 118.797 102.917 118.67 101.904 119.362 C 99.576 120.953 97.486 122.713 95.095 124.259 C 85.775 130.284 76.154 134.581 65.158 135.903 C 60.357 136.48 57.755 134.735 57.755 130.027 C 57.755 125.002 60.57 121.987 64.104 118.873 C 65.03 118.057 66.376 116.914 67.671 116.914 L 63.32 116.914 C 57.317 116.914 51.218 115.422 50.216 108.562 C 49.486 103.563 50.469 98.546 55.323 96.103 C 58.574 94.466 61.057 94.389 64.672 94.389 C 64.84 94.389 67.227 94.504 67.265 94.389 C 67.508 93.656 66.995 91.115 66.995 90.199 C 66.995 86.537 70.327 83.824 73.804 85.574"
                stroke-width="5"
                stroke="#220135"
                stroke-linecap="round"
                fill="none"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
          <div>Languages</div>
        </div>
        <div className="font-medium text-6xl md:text-[10rem] font-['Abril_Fatface'] text-center ">
          TRANSLATION
        </div>
      </div>

      <div className="bg-black w-full py-28 flex flex-col md:flex-row px-4 md:px-16 lg:px-20 xl:px-96 justify-between">
        <div className="text-3xl md:text-5xl font-['Abril_Fatface'] text-white mb-6 md:mb-0">
          Transmutation Tools:
          <br /> What We Translate
        </div>
        <div className="text-white text-lg md:text-xl leading-relaxed font-['Nunito'] w-full md:w-[480px]">
          We waltz with words from varied platforms - audios, videos, manuals,
          books, websites, reports, interviews, and texts. We harmonize the
          dissonance between languages and platforms, spinning a symphony of
          seamless translation. Your media is our canvas, and languages, our
          palette.
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          backgroundImage: `radial-gradient(rgba(255, 255, 255, 0.294) 1px, transparent 1px),
                          radial-gradient(rgba(255, 255, 255, 0.447) 1px, rgb(0, 0, 0) 1px)`,
          backgroundPosition: "0 0, 30px 30px",
          backgroundSize: "60px 60px",
          borderRadius: 0,
        }}
        className="px-4 md:px-16 lg:px-96 py-28"
      >
        <div className="text-4xl md:text-5xl font-['Abril_Fatface'] text-white">
          Language-Wizards At Your Service
        </div>
        <div className="flex flex-col gap-12 mt-16">
          <div className="w-full md:w-[560px] text-white text-lg md:text-xl leading-relaxed font-['Nunito']">
            Ready to juggle your documents and audio in an enthralling game of
            language-baton exchange. We can whirl the baton from one language to
            another:
          </div>
          <div className="w-full md:w-[560px] text-white text-lg md:text-xl leading-relaxed font-['Nunito']">
            Arabic, Chinese, French, Georgian, Haitian Creole, Hungarian,
            Italian, Japanese, Mandarin, Polish, Portuguese, Russian, Spanish,
            Tagalog, Ukrainian, Vietnamese, and the list goes on. Stepping on
            the tongue of languages like a linguistic acrobat, we never trip!
          </div>
          <div className="w-full md:w-[560px] text-white text-lg md:text-xl leading-relaxed font-['Nunito']">
            Contrary to the popular belief that being a native speaker is a
            ticket to translation mastery, we know it’s a drop in the ocean.
            Just like being a bio-degree holder doesn’t make one a surgeon,
            native speaking doesn’t bestow the crown of translation finesse. We
            navigate the labyrinth of language nuances with our practiced
            artistry.
          </div>
        </div>
      </div>

      <div className="bg-black flex flex-row w-full items-center justify-between p-40">
        <div className="w-32">
          <svg viewBox="0 0 200 200">
            <path
              d="M 73.587 79.826 C 73.587 76.773 73.354 74.814 75.814 72.709 C 78.225 70.647 81.446 70.93 84.38 70.93 C 86.255 70.93 87.83 70.713 88.637 72.808 C 89.514 75.084 90.519 76.832 90.519 79.382 C 90.519 80.739 90.295 80.466 90.964 79.48 C 92.583 77.099 95.322 75.109 97.648 73.5 C 101.822 70.611 107.176 68.261 112.352 68.261 C 113.824 68.261 118.766 68.332 119.036 70.485 C 119.271 72.366 120.388 75.268 118.59 76.935 C 117.627 77.827 117.044 79.096 116.114 80.024 C 115.953 80.185 115.251 80.243 115.322 80.172 C 115.845 79.651 116.864 79.403 117.525 79.134 C 123.355 76.764 129.498 75.827 135.794 76.292 C 138.05 76.459 139.471 77.614 141.314 78.739 C 142.411 79.408 142.57 80.562 143.319 81.161 C 145.194 82.658 141.693 86.237 141.116 87.389 C 140.883 87.854 139.912 88.999 140.918 89.143 C 142.804 89.412 144.615 89.673 146.439 90.28 C 149.473 91.289 152.931 92.837 154.434 95.84 C 156.594 100.153 153.454 103.969 150.226 106.688 C 146.558 109.778 142.852 110.652 138.196 110.519 C 137.174 110.49 136.609 109.858 135.868 109.283 C 135.666 109.126 135.065 112.579 134.977 112.842 C 132.814 119.321 126.892 128.328 119.828 130.091 C 112.559 131.905 93.196 133.345 98.094 120.404 C 98.48 119.382 99.009 117.859 99.777 117.092 C 100.485 116.385 99.146 117.813 98.539 117.982 C 92.815 119.569 86.261 120.877 80.369 119.316 C 74.545 117.774 69.985 113.862 66.11 109.53 C 63.227 106.306 65.998 102.3 68.017 99.819 C 68.578 99.129 69.748 97.853 70.69 97.644 C 71.732 97.413 72.06 97.955 70.864 98.286 C 67.963 99.091 64.799 99.398 61.804 99.398 C 58.668 99.398 55.094 99.905 52.199 98.459 C 44.911 94.822 40.23 83.721 48.634 78.764 C 52.281 76.613 57.254 74.489 61.556 74.489 C 64.066 74.489 67.451 73.891 69.799 74.933 C 71.663 75.76 74.868 77.492 75.814 79.381"
              stroke-width="10"
              stroke="rgb(255, 255, 255)"
              stroke-linecap="round"
              fill="none"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="w-32">
          <svg viewBox="0 0 200 200">
            <path
              d="M 45.109 116.589 C 45.109 108.64 50.316 100.116 53.819 93.211 C 56.596 87.736 59.779 82.457 62.821 77.125 C 65.405 72.597 67.286 67.432 70.469 63.275 C 71.118 62.427 73.026 59.572 73.286 61.919 C 74.249 70.594 73.559 79.838 73.396 88.558 C 73.108 104.02 71.538 119.655 70.14 135.056 M 99.78 70.42 C 90.933 71.896 88.033 83.292 86.899 90.427 C 85.664 98.198 84.718 107.189 88.582 114.317 C 90.432 117.73 96.305 121.021 100.109 119.557 C 106.272 117.182 112.443 108.94 114.6 102.701 C 117.971 92.949 119.154 83.952 112.294 75.366 C 108.43 70.529 104.402 71.739 99.121 71.739 M 134.517 70.394 C 130.579 75.556 127.031 82.41 125.766 88.483 C 124.45 94.802 122.694 103.954 126.244 110.04 C 127.447 112.102 129.167 115.733 132.201 116.311 C 135.253 116.892 140.838 112.741 143.415 111.268 C 145.983 109.801 147.172 107.307 149.077 105.312 C 150.692 103.621 151.275 100.578 152.019 98.504 C 153.326 94.864 153.71 90.598 153.71 86.782 C 153.71 83.375 153.284 78.069 150.695 75.217 C 147.535 71.734 137.532 69.617 133.193 72.096 M 101.097 131.099 C 107.243 128.022 113.714 126.338 120.529 125.529 C 131.78 124.192 144.261 123.52 155.109 127.141 M 113.613 139.013 C 118.831 138.433 124.072 135.802 129.421 135.056 C 135.287 134.238 141.281 133.737 147.206 133.737"
              stroke-width="10"
              stroke="rgb(255, 255, 255)"
              stroke-linecap="round"
              fill="none"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="w-32">
          <svg viewBox="0 0 200 200">
            <path
              d="M 43.268 68.491 C 45.299 73.362 49.466 84.503 49.894 90.103 M 96.026 42.825 C 91.393 56.451 81.245 85.486 77.722 92.618 M 106.911 105.701 C 115.338 96.409 133.856 76.994 140.501 73.674 M 121.449 128.144 C 128.309 126.305 144.878 122.279 156.268 120.889 M 120.076 150.692 C 123.463 151.132 131.74 153.176 137.755 157.825"
              stroke-width="10"
              stroke="rgb(255, 255, 255)"
              stroke-linecap="round"
              fill="none"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="w-32">
          <svg viewBox="0 0 200 200">
            <path
              d="M 153.363 83.39 C 148.042 79.345 140.629 77.638 138.057 71.234 M 121.162 126.697 C 118.728 124.872 115.335 123.852 112.644 122.407 C 110.466 121.237 108.036 119.386 106.187 117.741 C 105.006 116.692 103.513 114.329 102.949 112.925 M 98.946 127.759 C 99.478 127.759 99.86 128.189 100.376 128.351 C 101.675 128.761 102.622 129.284 103.797 129.937 C 104.108 130.11 106.052 131.317 105.461 131.701 C 104.047 132.62 101.904 132.506 100.317 132.861 C 98.666 133.233 99.692 130.942 100.001 130.316 M 158.512 71.129 C 159.679 72.425 159.972 73.588 159.999 75.203 C 160.091 80.694 154.893 85.222 151.728 88.856 C 147.425 93.796 144.545 99.853 140.254 104.78 C 135.404 110.347 130.82 116.161 125.96 121.739 C 124.177 123.787 122.148 125.565 120.369 127.607 C 119.619 128.468 118.26 129.876 117.025 130.039 C 112.934 130.582 109.364 133.091 105.219 133.684 C 103.52 133.927 99.179 135.051 98.562 132.549 C 98.231 131.205 98.711 129.682 98.397 128.335 C 98.255 127.722 98.322 125.722 98.508 125.179 C 99.156 123.29 98.863 120.89 99.343 118.839 C 99.881 116.536 101.219 115.361 102.461 113.455 C 104.481 110.355 106.637 107.301 108.749 104.187 C 110.588 101.475 112.36 98.819 114.515 96.346 C 116.906 93.6 118.745 90.389 121.127 87.654 C 125.1 83.094 129.399 79.031 133.68 74.771 C 135.887 72.578 138.565 70.94 140.589 68.616 C 142.523 66.396 144.396 64.616 147.869 65.519 C 149.915 66.05 151.572 66.75 153.569 67.476 C 155.176 68.06 156.748 69.968 158.057 71.132 M 39 131.135 C 41.448 129.572 43.829 127.418 46.567 126.402 C 49.153 125.442 52.187 124.996 54.923 125.459 C 58.251 126.025 60.398 128.075 62.534 130.548 C 63.301 131.435 64.118 132.265 64.942 133.098 C 65.607 133.77 66.324 134.722 67.201 135.132 C 67.935 135.474 68.714 134.611 69.266 134.242 C 71.162 132.973 73.159 131.962 75.389 131.456 C 77.869 130.894 80.287 130.8 82.444 132.366 C 83.729 133.298 84.979 134.344 86.354 135.138 C 87.205 135.63 88.914 134.713 89.68 134.196"
              stroke-width="10"
              stroke="rgb(255, 255, 255)"
              stroke-linecap="round"
              fill="none"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="w-32">
          <svg viewBox="0 0 200 200">
            <path
              d="M 111.499 135.487 C 107.724 135.487 107.069 133.3 104.389 131.449 C 96.149 125.755 89.128 117.881 83.444 110.448 C 75.664 100.276 68.32 89.065 65.02 77.333 C 63.681 72.565 63.719 64.849 64.791 60.058 C 66.357 53.063 71.931 60.755 74.345 62.297 C 83.955 68.433 90.442 82.017 93.688 91.249 C 94.899 94.698 97.739 97.037 97.739 91.623 C 97.739 82.248 100.405 75.537 105.689 67.266 C 108.483 62.893 124.561 38.208 131.834 49.371 C 135.891 55.604 134.891 62.684 134.891 70.063 C 134.891 85.59 126.758 100.178 121.056 114.859 C 117.506 123.994 113.777 133.538 108.746 142.198 C 107.355 144.592 105.494 153.584 103.243 154.5"
              stroke-width="10"
              stroke="rgb(255, 255, 255)"
              stroke-linecap="round"
              fill="none"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="w-32">
          <svg viewBox="0 0 200 200">
            <path
              d="M 91.667 60.516 C 90.433 60.823 91.196 67.127 91.196 67.877 L 91.196 138.516 M 62.422 91.058 C 70.919 91.058 79.485 90.667 87.919 91.868 C 99.54 93.521 110.572 96.913 122.014 99.255 C 127.242 100.326 132.088 101.396 137.422 101.396 M 71.856 63.805 C 72.094 65.932 74.391 68.252 75.472 69.966 C 80.433 77.824 85.583 85.561 90.724 93.303 C 97.025 102.792 101.818 112.797 107.496 122.593 C 109.587 126.202 111.108 130.444 113.365 133.818 M 67.61 120.191 C 68.138 116.686 72.316 112.862 74.32 110.115 C 82.024 99.553 89.791 88.557 98.951 79.18 C 103.836 74.18 108.838 68.634 114.307 64.276"
              stroke-width="10"
              stroke="rgb(255, 255, 255)"
              stroke-linecap="round"
              fill="none"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </div>
      <div className="bg-black w-full py-28 flex flex-col md:flex-row px-4 md:px-16 xl:px-96 lg:px-20 justify-between">
        <div className="text-3xl md:text-5xl font-['Abril_Fatface'] text-white mb-6 md:mb-0">
          Transmutation Tools:
          <br /> What We Translate
        </div>
        <div className="text-white text-lg md:text-xl leading-relaxed font-['Nunito'] w-full md:w-[480px]">
          We waltz with words from varied platforms - audios, videos, manuals,
          books, websites, reports, interviews, and texts. We harmonize the
          dissonance between languages and platforms, spinning a symphony of
          seamless translation. Your media is our canvas, and languages, our
          palette.
        </div>
      </div>
      <div className="bg-black xl:px-96 lg:px-20 py-40">
        <div className=" text-6xl font-['Abril_Fatface'] text-white mb-8">
          See Us in Action
        </div>
        <div className="flex flex-row h-screen gap-4">
          <div className="flex flex-col gap-4 object-cover overflow-hidden grayscale">
            <img src={img1} alt="" className="w-[580px] h-80 object-cover " />
            <img
              src={img2}
              alt=""
              className="w-[580px] h-full  object-cover "
            />
          </div>
          <div className="flex flex-col gap-12 object-cover overflow-hidden grayscale">
            <img src={img3} alt="" className="w-[580px] h-full scale-110" />
            <img src={img4} alt="" className="w-[580px] h-80" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Translation;
