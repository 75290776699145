import React from "react";

const Apply = () => {
  return (
    <>
      <div
        id="head"
        className="flex flex-col px-4 sm:px-6 md:px-10 lg:px-16 xl:px-24 pb-20 max-w-4xl mx-auto"
      >
        <div className="font-bold text-3xl text-center">
          Current Hiring Status: Independent Contractors Only
        </div>
        <div className="font-black text-2xl text-center">
          No Employee Openings at This Time
        </div>
        <div className="font-large font-bold text-xl my-8 text-center">
          — How to Apply for Opportunities —
        </div>

        <div className="font-bold text-lg">Employee Positions:</div>
        <div className="my-2">
          1. Inquiry Submission: Email us at{" "}
          <a href="mailto:frontoffice@htsteno.com" className="text-blue-500">
            frontoffice@htsteno.com
          </a>{" "}
          with the subject line: "Employee Opening (W-2) Inquiry." Please paste
          your resume directly into the email body—attachments will not be
          opened.
        </div>
        <div className="my-2">
          2. Qualifications: Include details about your qualifications, such as
          education, training, experience, and certifications. A minimum of 1
          year of experience in typing from audio or video recordings is
          required.
        </div>
        <div className="my-8">
          <div>
            After receiving your inquiry, we will provide information about the
            job, pay scale, and requirements. Successful candidates will need to
            pass a skills test and undergo a background check.
          </div>
          <div className="italic">
            Note: Inquiries received outside of business hours will be addressed
            on the next business day.
          </div>
        </div>

        <div className="font-bold text-lg">Independent Contractors:</div>
        <div>
          Work remotely from anywhere within the U.S. We do not outsource work
          overseas. We frequently engage qualified independent contractors to
          manage workloads that exceed our in-house capacity. All contractors
          must be U.S. citizens or legal residents living and working in the
          U.S. Please see the qualifications below.
        </div>

        <div className="font-bold mt-4 text-lg">How to Apply:</div>
        <div>
          - Inquiry Submission: Send an email to{" "}
          <a href="mailto:frontoffice@htsteno.com" className="text-blue-500">
            frontoffice@htsteno.com
          </a>{" "}
          with "Independent Contractor Inquiry" as the subject line. Paste your
          resume into the email body—attachments will not be opened.
        </div>

        <div className="my-8 font-bold text-xl text-center">
          — Qualifications —
        </div>

        <div className="my-4 text-lg text-center">
          English Transcription Contractor Qualifications
        </div>
        <div className="text-center">
          Applicants must meet the following technical requirements:
        </div>
        <ul className="space-y-2 font-['Nunito'] text-black">
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            A personal computer with at least 200GB RAM, 20GB of free hard drive
            space, running Windows 10, and Microsoft Word 2010 or later.
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            A minimum internet download speed of 45 Mbps.
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            Our proprietary transcription software may not be compatible with
            Apple devices. Some Windows simulators like Parallels might work.
          </li>
        </ul>

        <div className="my-4 font-bold">Important Notes:</div>
        <ul className="space-y-2 font-['Nunito']">
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            We do not accept applications from students or those seeking
            short-term engagements.
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            English must be your native language.
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            The content may include offensive or disturbing language.
          </li>
        </ul>

        <div className="my-4 font-bold">Additional Requirements:</div>
        <ul className="space-y-2 font-['Nunito']">
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            A recent nationwide criminal background check with no arrest record
            (or a valid Global Entry card, not TSA Precheck). Background checks
            are at your expense.
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            We recommend using Goodhire.com for background checks and sharing
            the results with us. However, you may use any reputable service that
            conducts local, state, and federal searches.
          </li>
        </ul>

        <div className="my-4 font-bold">
          Foreign Language Translation Contractors
        </div>
        <div>
          We are expanding our network of independent contractors to include
          select foreign language translators, with a preference for
          court-certified Spanish translators based in Florida.
        </div>

        <div className="my-4 font-bold">Additional Requirements:</div>
        <ul className="space-y-2 font-['Nunito']">
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            U.S. citizens or legal residents.
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            Must pass a criminal background check (or hold a valid Global Entry
            or Trusted Traveler card, not TSA Precheck).
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            Certification through a recognized agency (e.g., state or federal
            court, ATA, ALTA, ACTFL) is required. Proof of current certification
            is mandatory. Note: A medical interpreter certificate does not
            qualify.
          </li>
        </ul>

        <div className="my-8 font-bold text-xl text-center">
          — Application Process —
        </div>
        <div className="mb-4 text-center">
          All inquiries must be submitted via email. Clearly state if you are
          applying for an employee position or as an independent contractor, and
          specify whether you are applying for a translator role (and the
          language) or for general English transcription.
        </div>
        <ul className="space-y-2 font-['Nunito']">
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            Email:{" "}
            <a href="mailto:frontoffice@htsteno.com" className="text-blue-500">
              frontoffice@htsteno.com
            </a>
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            Resume Submission: Attach your resume in PDF or send your LinkedIn
            profile.
          </li>
          <li className="relative pl-6">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
            Important: Use your personal email address when applying. Due to the
            high volume of inquiries, we do not maintain a resume database or
            mailing list.
          </li>
        </ul>
      </div>
    </>
  );
};

export default Apply;
