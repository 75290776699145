import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../components/main";
import Contact from "../components/contact";
import Translation from "../components/translation";
import Transcription from "../components/transcription";
import Apply from "../components/apply";
import Faq from "../components/faq";
import Header from "../components/header";
import Footer from "../components/footer";

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/translation" element={<Translation />} />
        <Route path="/transcription" element={<Transcription />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
