import React from "react";
import icon from "../images/linkedin.png";

const Footer = () => {
  return (
    <>
      <footer className="font-['Work_Sans'] bg-[#001f54] text-white flex flex-col items-center px-4 md:px-20 lg:px-32 py-8 md:py-16">
        <div className="w-full flex flex-col md:flex-row justify-between flex-wrap">
          {/* Company Section */}
          <div className="m-5 text-center md:text-left">
            <p className="font-bold text-md mb-2">Company</p>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="/#head" className="hover:underline">
                  Home
                </a>
              </li>
              <li>
                <a href="/#about" className="hover:underline">
                  About
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Services Section */}
          <div className="m-5 text-center md:text-left">
            <p className="font-bold text-md mb-2">Services</p>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="/translation#head" className="hover:underline">
                  Translation
                </a>
              </li>
              <li>
                <a href="/transcription#head" className="hover:underline">
                  Transcription
                </a>
              </li>
              <li>
                <a href="/apply" className="hover:underline">
                  Opportunities
                </a>
              </li>
            </ul>
          </div>

          {/* Support Section */}
          <div className="m-5 text-center md:text-left">
            <p className="font-bold text-md mb-2">Support</p>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="/faq" className="hover:underline">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-between items-center mt-8 md:mt-12 flex-wrap">
          <p className="text-sm text-center md:text-left">
            Copyright © 2024 by American High-Tech Transcription & Reporting,
            Inc. All rights reserved.
          </p>
          <div className="mt-4 md:mt-0">
            <a
              href="https://www.linkedin.com/company/american-high-tech-transcription-reporting-inc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={icon}
                alt="LinkedIn"
                className="w-6 h-6 mx-auto md:mx-0"
              />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
