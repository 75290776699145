import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";

const Faq = () => {
  return (
    <div className="px-4 sm:px-6 md:px-10 lg:px-16 xl:px-96">
      <div className="font-bold text-4xl sm:text-5xl text-center pb-10">
        FAQ
      </div>

      <div className="font-medium text-lg sm:text-xl font-['Nunito'] flex flex-col gap-4 text-gray-800">
        <div>
          Do you still have questions about the transcription and translation
          services of American High-Tech Transcription?
        </div>
        <div>We've done our best to anticipate your questions below.</div>
      </div>

      <Accordion allowMultiple className="py-8 pb-40">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. How much do your services cost?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. Pricing is based on individual project requirements. We bid
                on projects per page, per hour, per recorded minute, or per
                translated word. There are no minimums required. We recognize
                that your business and project is unique. There are no monthly
                fees or contracts required. We give discounts for multi-year
                contracts. Contact us for a quote, and we will customize a bid.
                We can save you as much as 50 percent or more over the costs of
                in-house transcription. Please call for a customized quote (727)
                535-1066.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. How do we save customers money?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. We eliminate the need for your department or company to hire,
                train, and supervise a transcription or translation pool. This
                also cuts the associated costs of benefits or overtime.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. What about confidentiality and security?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. All our employees and select independent contractors undergo
                criminal background checks and sign a strict confidentiality
                agreement. Work is performed by native English speakers, or in
                the case of translations, by U.S. citizens or legal residents
                who are native speakers and certified in that language.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. How do you maintain quality?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. We have proofreaders who listen to the original audio/video
                recording to minimize unintelligible dialogue and other errors.
                This ensures highly accurate transcriptions.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. What is your turnaround time?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. Normal turnaround time is five to seven business days.
                However, we can meet any project deadline, including same day or
                next day.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. Do you provide foreign language translations?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. Yes, our foreign language translators are state court
                certified and/or certified through nationally recognized testing
                agencies, such as the American Translators Association (ATA),
                Academic Language Therapy Association (ALTA), and American
                Council On the Teaching Of Foreign Languages (ACTFL). Many of
                our linguists have security clearances through various U.S.
                federal agencies. Call us about languages not listed in the
                transcription page.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. Why use a certified translator?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. A forensic transcript must meet stringent requirements to be
                reliable. Certified translators have experience in testifying as
                an expert witness and are trained in transcript protocols,
                research skills, and maintaining neutrality and adherence to
                ethical standards. A certified translator also has analytic and
                problem-solving skills, pays attention to details, and has a
                highly-tuned ear. The use of non-certified or untrained native
                speakers to translate materials puts your transcript or project
                at risk and can portray your company as unknowledgeable.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. How is payment made?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. We accept payment in U.S. currency only via credit card (Visa
                or MasterCard), ACH, EFT, and U.S. check or money order.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. Can I get a refund for my purchase?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. Refunds are available under specific conditions. Please
                contact our support team to review your eligibility and initiate
                a refund request.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q. How are transcriptions delivered?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. Completed transcriptions are delivered electronically via
                secure methods. Delivery times will be confirmed at the time of
                your order.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Q: What is the cancellation policy?
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A. Cancellations are governed by the terms outlined in your
                service agreement. For further details, please refer to your
                agreement or contact support.
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Faq;
